td{
    border: 1px solid gray;
    padding: 2px;
}
table{
    width: 80%;
    position: relative;
    left: 10%;
    border-collapse: collapse;
}
.time{
    width: 180px; 
}
.ip{
    width: 250px;
}
.eventType{
    width: 150px;
}