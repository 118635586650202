.challengeModalMain {
  visibility: hidden;  
  position: fixed;
  opacity: 0;
  width: 100vw;
  top: 6rem;
  right: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 111;

}
@keyframes fadeOut {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

.modalCard{
    visibility: hidden;
  opacity: 0;
background-size: contain;
background-repeat: no-repeat;
  width: 40rem;
  position: fixed;
left: calc(100vw - 80rem);
top: 50%;
  transition: all .5s;
  z-index: 112;

}
.active {
    visibility: visible;
    transition: opacity 0.5s;
    opacity: 1;
    animation: none;
    
  }

.modalCard.active{
  visibility: visible;
    transition: all 0.4s;
  transform: scale(1.2);
  
    opacity: 1;
    top:30vh;
    animation: none;
}
.modal-header{
  height: 6.8437499999rem;
  background-repeat: no-repeat;
  width: 40rem;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAdIAAABQCAYAAACtb/RRAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHuSURBVHhe7dshUhxRGEbRGZYAAiQYWABodoCKiUYHk4XEJDoag2IHaFhAFMgY1jAxT3YxlbnV1TXFOaZ/1/LWE996s9ms5nRxcjTvDwDgA3/+vq/HOYuD8QUAdiCkABAIKQAEQgoAgZACQCCkABAIKQAEeUdad6JXp2fjAoD/9/z2Oq7d1J2pFykABEIKAIGQAkAgpAAQCCkABEIKAIGQAkCwdUe69E70+931uAD4jH78fBrXbubemXqRAkAgpAAQCCkABEIKAIGQAkAgpAAQCCkABOvz40M7UQD21tI7Uy9SAAiEFAACIQWAQEgBIBBSAAiEFAACIQWAYOuO1E4UgH02987UixQAAiEFgEBIASAQUgAIhBQAAiEFgEBIASDIO9K6E73//TiuaV9vb8YFwGc0dye27UztSAFgRkIKAIGQAkAgpAAQCCkABEIKAIGQAkCw+I4UAJZkRwoACxJSAAiEFAACIQWAQEgBIBBSAAiEFAACIQWAQEgBIBBSAAiEFAACIQWAQEgBIBBSAAiEFAACIQWAQEgBIBBSAAiEFAACIQWAQEgBIBBSAAiEFACC9fnx4Wbck65Oz8Y17duXy3EBwP759fAyrmnPb6/jmuZFCgCBkAJAIKQAEAgpAARCCgCBkAJAIKQAsLPV6h9kM0kWq42LlQAAAABJRU5ErkJggg==');
}

.modal-body{
  position: relative;
  top: -1px;
  font-size: small;
  height: auto;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAdIAAAAIBAMAAAC/nGP4AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJUExURSYXElo3M8ykcd3h6BcAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAoSURBVEjHYwABQSBQGq4A5DkGGBj16bAAoz4dfmDUp8MPjDSfMjAAAMIW6tEnzurqAAAAAElFTkSuQmCC');
  background-size: contain;
}
.modal-flag{
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAdIAAABQCAYAAACtb/RRAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAIASURBVHhe7dshbhRAGIbhXY5QRJGtKQegujdAYdBoanqQmqLRGBQ3qC4HQLUSwxkWM3LSSfbNZtP0ecz8N3gz4ttenJ7sNs+4PDsf19zXTx/GBQAvz7efv8c19/D0OK65N+MFAPYgpAAQCCkABEIKAIGQAkAgpAAQCCkABHlHenN9NS4AeHlu7+7HNWdHCgAHJKQAEAgpAARCCgCBkAJAIKQAEAgpAARCCgCBkAJAIKQAEAgpAARCCgCBkAJAIKQAEAgpAARCCgCBkAJAIKQAEAgpAARCCgCBkAJAIKQAEAgpAATbi9OT3binLs/OxzV3c301rv38+P5rXHOfv3wcFwCv0aE7cXt3P665h6fHcc35kQJAIKQAEAgpAARCCgCBkAJAIKQAEAgpAAR5R7pSd6YAUKx2oit2pABwQEIKAIGQAkAgpAAQCCkABEIKAIGQAkCw3JGu2JkCcEyH3omu+JECQCCkABAIKQAEQgoAgZACQCCkABAIKQAE293u+Rnp+3dv7UwBOJpj70T//P23HeeUHykABEIKAIGQAkAgpAAQCCkABEIKAIGQAkCw3JGuHHtnCsDrduid6IofKQAEQgoAgZACQCCkABAIKQAEQgoAgZACQJB3pCt1ZwoARd2JrviRAkAgpAAQCCkABEIKAIGQAkAgpAAQCCkA7G2z+Q+Dp0kWSC46WwAAAABJRU5ErkJggg==');
  height: 6.9537499999rem;
  background-repeat: no-repeat;
  width: 40rem;
  background-size: contain;
  position: relative;
  top: -2px;
}
.modalChallengeName{
  color:#523713;
  font-size: large;
  font-weight: 500;
  margin: 40px 0px 10px 0px;
}
.modalChallengePoints{
  margin: 0px 0px 10px 0px;
  color: #523713;
}
.modalChallengePoints::after{
  content:" Puan"
}
.modalDesc{
  color: #523713;
  margin: 0;
  padding: 10px 75px;
  line-height: 1rem;
  overflow-wrap: break-word;
  text-align: center;
  
}
.button-group{
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 0 auto;
  padding-top: calc(6vh - 35px);
}
.flagInput{
  width: 80%;
  display:inline-block ;
  height: 30px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.flagInput::placeholder{
  font-size: 1rem;
  margin: 1rem;
}
button.flagSubmit {
  transition: box-shadow 0.2s;
  outline: none;
  width: 20%;
  padding-right: 6%;
  height: 34px;
  border: 1px solid #5d7f7d;
  background-color: rgba(0, 0, 0.3);
  border-radius: 20px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: white;
  padding-left: 10px;
}
button.flagSubmit::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

button.flagSubmit:focus {
  transition: box-shadow 0.2s;
  border: 1px solid #a5dfdb;
  box-shadow: 0px 0px 20px #9ff6f0;
}
button.flagSubmit:hover {
  box-shadow: 0px 0px 20px #9ff6f0;
  transition: box-shadow 0.2s;
}
.wrongAnswer{
  color: red;
  width: 100%;
  text-align: center;
  margin-top: 0;
}
.gain{
  color: #523713;
}
.gaintooltip{
  border: 0;
  transition:all 250ms linear;
  position: absolute;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #CCA471;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMEAAAAYCAIAAADMEQ4sAAAAAXNSR0IArs4c6QAAAGtJREFUaIHt0qEVQAAARVFsbBHFCiaR7cAoqqC9ozj3xp9+eOOxzQM8LOv+uh/X+bpPH//h/zREpSEqDVFpiEpDVBqi0hCVhqg0RKUhKg1RaYhKQ1QaotIQlYaoNESlISoNUWmISkNUGqK6AfESBn/UF7czAAAAAElFTkSuQmCC');
  background-size: 270px 100%; /* Height is 100%, width scales automatically */
  left: 25px;
  width: 26rem;
  overflow:hidden;
  word-break: keep-all;
}
.gainContainer{
  position: fixed;
  z-index: 12;
  right: 1.5vw;
  top: 7rem;
}
.failtooltip{
  border: 0;
  transition:all 250ms linear;
  position: absolute;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #CCA471;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMEAAAAYCAIAAADMEQ4sAAAAAXNSR0IArs4c6QAAAGtJREFUaIHt0qEVQAAARVFsbBHFCiaR7cAoqqC9ozj3xp9+eOOxzQM8LOv+uh/X+bpPH//h/zREpSEqDVFpiEpDVBqi0hCVhqg0RKUhKg1RaYhKQ1QaotIQlYaoNESlISoNUWmISkNUGqK6AfESBn/UF7czAAAAAElFTkSuQmCC');
  background-size: 270px 100%; /* Height is 100%, width scales automatically */
  left: 25px;
  width: 20rem;
  overflow:hidden;
  word-break: keep-all;
}
.failContainer{
  position: fixed;
  z-index: 12;
  right: 1.5vw;
  top: 8.5rem;
}
.soltooltip{
  border: 0;
  transition:all 250ms linear;
  position: absolute;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #CCA471;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMEAAAAYCAIAAADMEQ4sAAAAAXNSR0IArs4c6QAAAGtJREFUaIHt0qEVQAAARVFsbBHFCiaR7cAoqqC9ozj3xp9+eOOxzQM8LOv+uh/X+bpPH//h/zREpSEqDVFpiEpDVBqi0hCVhqg0RKUhKg1RaYhKQ1QaotIQlYaoNESlISoNUWmISkNUGqK6AfESBn/UF7czAAAAAElFTkSuQmCC');
  background-size: 270px 100%; /* Height is 100%, width scales automatically */
  left: 25px;
  width: 20rem;
  overflow:hidden;
  word-break: keep-all;
}
.solContainer{
  position: fixed;
  z-index: 12;
  right: 1.5vw;
  top: 10rem;
}
.tooltiptext{
  color: white;
  word-break: keep-all;
  overflow-wrap: hidden;
  margin: 8px 8px 0 8px;
  line-height: 24px;
  overflow: hidden;
  width: 25rem;

}
.keywords{
  color: #523713;
  text-align: center;
  margin: 0;
}
.hintContainer{
  position: fixed;
  left: 20px;
  top:4.5rem
}
.hint-buy{
  padding:1px;
  margin-left:8px;
    background-color: transparent;
    color: #523713;
    border: 0;
}
.hint-buy:hover{
  background-color: rgba(0,0,0,0.4);
}
.hint{
    color: #523713;
    white-space:none;
    font-size: small;
    padding:1px;
    margin-left:8px;
}